import styled from '@emotion/styled'

import ButtonBase from '@/components/Button'
import { GridContainer } from '@/components/Grid'
import WysiwygBase from '@/components/Wysiwyg'

import { Overlay as ItemOverlay } from '@/components/HomeHero/Item/style'

import { mediaMax, sectionSubtitle, sectionTitle } from '@/styles/mixins'

export const ItemCountDownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${ItemOverlay} {
    background: none;
  }
`

export const DisplayWrapper = styled.div`
  width: 100%;
`

export const ContainerCountDown = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
`

export const CountDownOverlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  // background-color: ${({ theme }) => `${theme.colors.black}50`};

  ${mediaMax.sm} {
    align-items: flex-start;
  }
`

export const CountDownContent = styled(GridContainer)`
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${mediaMax.sm} {
    flex-direction: column;
    height: 58%;
    margin-top: 10rem;
  }

  ${mediaMax.xs} {
    flex-direction: column;
    height: 58%;
    padding: 5rem 0;
    margin-top: 10rem;
  }
`

export const CountDownContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`

export const CountDownWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: center;
`

export const CountDown = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
max-width: max-content;
text-align: center;

&:nth-of-type(1) {
  margin-right: 3rem;
}

&:nth-of-type(2) {
  margin-right: 0.5rem;
}

&:nth-of-type(4) {
  margin-left: 1.5rem;
}
`

export const TimeLetter = styled.span`
${sectionSubtitle()}
color: ${({ theme }) => theme.colors.white};
letter-spacing: 0.4rem;
`

export const Points = styled.div`
${sectionTitle()}
color: ${({ theme }) => theme.colors.white};
letter-spacing: 1rem;
font-size: 5rem;
width: 1.5rem;
`

export const Time = styled.span`
${sectionTitle()}
color: ${({ theme }) => theme.colors.white};
letter-spacing: 1rem;
margin-bottom: 10px;
font-size: 5rem;
`

export const CountDownTitle = styled.span`
  ${sectionTitle()}
  letter-spacing: 1rem;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 20px;

  ${mediaMax.xs} {
    letter-spacing: 0.5rem;
    margin-bottom: 2rem;
  }
`

export const CountDownText = styled(WysiwygBase)`
  max-width: 28vw;
  font-size: 1.4rem;
  color: ${({ theme }) => `${theme.colors.white}dd`};

  ${mediaMax.sm} {
    max-width: 60vw;
  }

  ${mediaMax.xs} {
    font-size: 1.8rem;
    line-height: 1.6em;
    max-width: 85vw;
  }
`

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
`

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  // background-color: ${({ theme }) => `${theme.colors.black}50`};
`

export const Content = styled(GridContainer)`
  z-index: 1;

  ${mediaMax.xs} {
    align-self: flex-end;
    display: flex;
    height: 65%;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5rem;
  }
`

export const ContentText = styled.div`

`

export const Subtitle = styled.span`
  ${sectionSubtitle()}
  display: block;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 34px;
  ${mediaMax.xs} {
    margin-bottom: 2.6rem;
  }
`

export const Title = styled.span`
  ${sectionTitle()}
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 34px;
  ${mediaMax.xs} {
    margin-bottom: 3rem;
  }
`

export const Text = styled(WysiwygBase)`
  max-width: 28vw;
  font-size: 1.4rem;
  color: ${({ theme }) => `${theme.colors.white}dd`};
  margin-bottom: 39px;

  ${mediaMax.xs} {
    font-size: 1.8rem;
    line-height: 1.6em;
    max-width: 85vw;
    margin-bottom: 4rem;
  }
`

export const Button = styled(ButtonBase)`
  display: inline-flex;
  width: auto;
  padding: 1.8rem 4rem;

  ${mediaMax.xs} {
    // margin-top: 4rem;
  }

  span {
    font-size: 1.2rem;
  }
`

export const GlobalContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: opacity .3s ease-out;

  &.hidden {
    opacity: 0;
  }
`

export const GlobalWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const GlobalCountDownWrapper = styled(GlobalWrapper)`
  display: none;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.active {
    display: flex;
  }
`
